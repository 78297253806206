<template>
	<v-sheet flat>
		<template v-if="logged_in">
			<template v-if="canAccess">
				<room v-if="start" :roomName="roomName"></room>

				<v-card v-else color="lightest" class="rounded-xl" flat>
					<v-card-text>
						<p>
							You're about to enter a remote video appointment. Please
							note that sessions are recorded automatically. If you have
							any concerns or questions, please get in touch with us
							using the live chat (bottom left).
						</p>
						<p>
							Would you like to enter the room as the moderator? If
							someone else is already the moderator, you will replace
							them. The moderator role can be changed later.
						</p>
						<v-checkbox
							label="I am the moderator"
							v-model="moderate"
						></v-checkbox>
					</v-card-text>
					<v-card-actions>
						<v-spacer></v-spacer>
						<v-btn @click="start = true" color="primary">Enter</v-btn>
					</v-card-actions>
				</v-card>
			</template>
			<v-card v-else color="lightest" class="rounded-xl" flat
				><v-card-text
					>Please wait to be admitted to the video conference</v-card-text
				></v-card
			>
		</template>
		<template v-else>
			<v-card color="lightest" class="rounded-xl" flat
				><v-card-text
					>Please
					<a @click="$root.$emit('openLogin')">log in</a></v-card-text
				></v-card
			>
		</template>
	</v-sheet>
</template>

<script>
import Room from "@c/video/Room.vue";
export default {
	name: "VideoContainer",
	props: {
		id: { type: String },
		moderator: {type: Boolean}
	},
	data: () => {
		return {
			start: false,
			moderate: null,
		};
	},
	components: { Room },
	computed: {
		auth() {
			return this.$store.state.auth.data;
		},
		logged_in() {
			return this.auth.id || false;
		},
		videoRoom() {
			return this.$store.state.videoRoom.data;
		},
		roomName() {
			return this.videoRoom.id;
		},
		canAccess() {
			return this.$store.getters["videoRoom/canAccess"];
		},
	},
	watch: {
		auth: {
			immediate: true,
			handler(value) {
				if (value) {
					this.$store.dispatch("videoRoom/start", this.id);
				}
			},
		},
		moderate(v) {
			if( v ){
				this.$store.dispatch("videoRoom/patch", {
					moderator: this.auth.id,
				});
			}
			else{
				this.$store.dispatch("videoRoom/patch", {
					moderator: null,
				});
			}
		},
	},
	created() {
		// if (
		// 	this.$router.history._startLocation !==
		// 	this.$router.currentRoute.fullPath
		// ) {
		// 	this.start = true;
		// }
	},
	destroyed() {
		this.$store.dispatch("videoRoom/leaveConference");
	},
};
</script>
