<template>
	<mw-dialog large v-model="dialog" title="Session Controls" hide-actions>
		<template v-slot:button>
			<v-list dense>
				<v-list-item @click="dialog = true" color="primary">
					<v-list-item-icon>
						<v-icon color="primary">
							mdi-cog
						</v-icon>
					</v-list-item-icon>
					<v-list-item-content>
						<v-list-item-title>
							Session Controls
						</v-list-item-title>
					</v-list-item-content>
				</v-list-item>
			</v-list>
		</template>
		<template v-slot:dialog-body>
			<v-tabs v-model="tab">
				<v-tab>
					Settings
				</v-tab>
				<v-tab>
					Moderator
				</v-tab>
				<v-tab>
					Invite
				</v-tab>
				<v-tab>
					Prompt
				</v-tab>
				<v-tab-item>
					<identity @close="dialog = false"></identity>
				</v-tab-item>
				<v-tab-item>
					<moderator-controls @close="dialog = false"></moderator-controls>
				</v-tab-item>
				<v-tab-item>
					<invite @close="dialog = false"></invite>
				</v-tab-item>
				<v-tab-item>
					<prompt-participants @close="dialog = false"></prompt-participants>
				</v-tab-item>
			</v-tabs>
		</template>
	</mw-dialog>
</template>

<style lang="less" scoped>
.v-tab {
	justify-content: left !important;
}
</style>
<script>
import Invite from "@c/video/Invite";
import Identity from "@c/video/Identity";
import MwDialog from "@c/ui/MwDialog";
import ModeratorControls from "@c/video/ModeratorControls";
import PromptParticipants from "@c/video/PromptParticipants";
export default {
	name: "SessionControls",
	components: {
		MwDialog,
		Invite,
		Identity,
		ModeratorControls,
		PromptParticipants
	},
	data: () => {
		return {
			dialog: false,
			tab: 0,
		};
	},
	computed: {
		id() {
			return this.$store.state.videoRoom.data.id;
		},
	},
};
</script>
