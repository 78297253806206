import firebase from "firebase";

export function uploadFileToStorage(file, path){
	return new Promise( (resolve, reject) =>{
		var storageRef = firebase.storage().ref();	
		var ref = storageRef.child(path);
		ref.put(file).then(function(child) {
			if( child ){
				resolve( child )
				
			}
		}).catch( err => {
			reject(err)
		})
	} )
}

export function getMetaData(path){
	return new Promise( (resolve, reject) => {
		var storageRef = firebase.storage().ref();	
		var ref = storageRef.child(path);
		ref.getMetadata().then(function(metadata) {
			resolve(metadata)
		}).catch(function(error) {
			reject(error)
		});
	})
}


export function deleteFileFromStorage(path){
	return new Promise( (resolve, reject) => {
		var storageRef = firebase.storage().ref();	
		var ref = storageRef.child(path);
		ref.delete().then(function() {
			resolve()
		}).catch(function(error) {
			reject( error )
		});	
	})
}

export function updateMetaData(meta, path){
	if( !meta ){ return; }
	return new Promise( (resolve, reject) => {
		var storageRef = firebase.storage().ref();	
		var ref = storageRef.child(path);
		ref.updateMetadata({customMetadata: meta}).then( (metadata) => {
			resolve(metadata)
		}).catch( (error) => {
			reject(error)
		});
	})
}

export function getFileFromStorage(path){
	return new Promise( (resolve, reject) => {
		var storage = firebase.storage();
		let ref = storage.ref(path);
		if( !ref ){
			reject()
		}
		else{
			ref.getDownloadURL().then( (url) =>{
				resolve(url);
			});
		}
	} )
}

export function listFiles(path){
	return new Promise( (resolve, reject) => {
		if( !path ){
			reject()
		}
		var storageRef = firebase.storage().ref();	
		var ref = storageRef.child(path);
		ref.listAll().then(function(list) {
			resolve( list )
		}).catch(function(error) {
			reject(error)
		});
	})
}
