<template>
	<v-card flat>
		<v-card-text>
			<p>Send this link to colleagues.</p>
			<v-alert color="lightest" class="pa-2 font-weight-light large-text">
				{{ link }}
				<copy-button :text="link"></copy-button>
			</v-alert>
		</v-card-text>
        <v-card-actions>
			<v-btn @click="$emit('close')" text color="secondary">Cancel</v-btn>
            <v-spacer></v-spacer>
        </v-card-actions>
	</v-card>
</template>

<script>
import CopyButton from "@c/buttons/CopyButton";
export default {
	name: "InviteColleagues",
	components: {
		CopyButton,
	},
	data: () => {
		return {};
	},
	computed: {
		id() {
			return this.$store.state.videoRoom.data.id;
		},
		link() {
			return "https://mw-join.web.app/video/" + this.id;
		},
	},
};
</script>
