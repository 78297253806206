<template>
	<v-card>
		<v-card-text>
			<p>
				Select the moderator below. This change takes effect immediately.
				The moderator will become visible to all participants in the room.
				Moderators can control their own audio and video settings.
			</p>
			<v-checkbox
				hide-details
				v-for="(person, i) in potentialModerators"
				:key="i"
				:label="person.label"
				:value="person.value"
				v-model="selected"
			>
			</v-checkbox>
		</v-card-text>
		<v-card-actions>
			<v-btn @click="$emit('close')" text color="secondary">Cancel</v-btn>
			<v-btn text color="error" v-if="active" @click="selected = null"
				>Remove moderator</v-btn
			>
			<v-spacer></v-spacer>
			<v-btn icon color="primary" @click="update"
				><v-icon>mdi-check-circle</v-icon></v-btn
			>
		</v-card-actions>
	</v-card>
</template>

<script>
export default {
	name: "ModeratorControls",
	data: () => {
		return {
			dialog: false,
			selected: null,
		};
	},

	computed: {
		localIdentity() {
			return this.$store.state.auth.data.id;
		},
		room() {
			return this.$store.state.videoRoom.data;
		},
		active() {
			return this.room.moderator == this.localIdentity;
		},
		potentialModerators() {
			const self = this;
			let people = Object.keys(self.room.identities).map((a) => {
				let label = self.room.identities[a];
				if (self.localIdentity == a) {
					label += " (You)";
				}
				return { value: a, label: label };
			});
			people = people.filter(
				(a) => !self.room.participants.includes(a.value)
			);
			return people.filter((a) => a.label);
		},
	},
	methods: {
		update() {
			if (this.selected) {
				if (this.selected !== this.room.moderator) {
					this.$store.dispatch("videoRoom/patch", {
						moderator: this.selected,
					});
				}
			} else {
				this.$store.dispatch("videoRoom/patch", {
					moderator: null,
				});
			}
			this.$emit("close");
		},
	},
	created() {
		this.selected = this.room.moderator;
	},
};
</script>
