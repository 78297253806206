<template>
	<v-card
		ref="wrapper"
		flat
		class="video-wrapper"
		:class="{ [size]: true }"
		@click="select"
	>
	</v-card>
</template>

<style lang="less">
.video-wrapper.preview {
	video {
		height: auto;
		width: 100%;
	}
}
.video-wrapper.main {
	video {
		height: auto;
		width: 100%;
	}
}
</style>
<script>
export default {
	name: "VideoTrack",
	props: {
		size: { type: String }, // full or preview
		track: { type: [Object, Map, Array] },
	},
	computed: {
		content() {
			return this.track.attach();
		},
	},
	methods: {
		attach() {
			this.$el.append(this.content);
		},
        select(){
            this.$emit( "select", this.track )
        }
	},
	watch: {
		content(){
			this.$el.innerHTML = "";
			this.attach();
		}
	},
	mounted() {
		this.attach();
	},
};
</script>
