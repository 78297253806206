<template>
	<v-sheet>
		<h3 class="font-weight-light">Documents</h3>

		<p>
			You can use this page to share any documents related to your project with the team. Note that anyone with access to the project,
			including your colleagues and the Meisterworks team, can access
			documents shared here. If you are unsure what the best permissions for
			your document are, please contact Meisterworks support.
		</p>
		<project-info :id="id"></project-info>
		<storage-list
			allow-upload
			:folder="`projects/${id}/clients/documents`"
		></storage-list>
		<project-navigation :id="id"></project-navigation>
	</v-sheet>
</template>

<script>
import ProjectInfo from "@c/project/ProjectInfo";
import StorageList from "@c/storage/StorageList.vue";
import ProjectNavigation from "@c/project/ProjectNavigation.vue";
export default {
	name: "Documents",
	props: {
		id: { type: String },
	},
	components: {
		StorageList,
		ProjectInfo,
		ProjectNavigation,
	},
};
</script>
