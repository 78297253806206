<template>
	<v-sheet>
		<v-list class="mb-6">
			<v-list-item>
				<v-list-item-content><b>File</b></v-list-item-content>
				<v-list-item-action><b>Action</b></v-list-item-action>
			</v-list-item>
			<v-list-item-group v-for="(item, i) in items" :key="i">
				<folder-list-item
					v-if="item.type == 'folder'"
					:allowUpload="allowUpload"
					:item="item"
					:home="folder"
				></folder-list-item>
				<file-list-item
					v-if="item.type == 'file'"
					:allowUpload="allowUpload"
					:item="item"
					:home="folder"
					@deleted="fileDeleted"
				></file-list-item>
			</v-list-item-group>
		</v-list>

		<upload-field
			label="Upload new document"
			v-if="allowUpload"
			:folderStem="folder"
			:folderPaths="folders"
			:exists="false"
			@complete="newFile"
		></upload-field>
	</v-sheet>
</template>
<script>
import Vuex from "vuex";
import { listFiles } from "@/assets/storageFunctions.js";
export default {
	name: "StorageList",
	props: {
		allowUpload: { type: Boolean },
		folder: { type: String },
		folders: { type: Array, default: () => [] },
		name: { type: String, default: "Documents" },
	},
	data: () => {
		return {
			dialog: false,
			items: [],
			current: false,
			focus: false,
		};
	},
	components: {
		UploadField: () => import("@c/storage/UploadField"),
		FolderListItem: () => import("@c/storage/FolderListItem"),
		FileListItem: () => import("@c/storage/FileListItem"),
	},
	computed: {
		...Vuex.mapState({
			user: (state) => state.auth.data.id,
		}),
	},
	created() {
		const self = this;
		if (!self.folder) {
			return;
		}
		listFiles("/" + self.folder).then((a) => {
			
			a.prefixes.forEach((p) => {
				p.type = "folder";
				self.items.push(p);
			});
			a.items.forEach((i) => {
				i.type = "file";
				self.items.push(i);
			});
		}).catch(E  => console.log(E));
	},
	methods: {
		newFile(file) {
			let obj = file.ref;

			obj.type = "file";
			if (!(this.current && this.current.type == "folder")) {
				this.items.push(obj);
			}
		},
		fileDeleted(item) {
			this.items.splice(this.items.indexOf(item), 1);
		},
	},
};
</script>
