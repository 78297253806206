<template>
	<span>
		<v-btn class="ma-2" @click="copy" icon >
			<v-icon>mdi-content-copy</v-icon>
		</v-btn>
		<v-snackbar color="primary" v-model="copied" text>{{$t('misc.copied')}}</v-snackbar>
	</span>
</template>

<style lang="less">
	
</style>

<script type="text/javascript">
	export default {
		name: "CopyButton", 
		props: {
			text: {},
		},
		data: () => {
			return {
				copied: false
			}
		},
		methods: {
			copy(){ 
				this.copied = true;
				navigator.clipboard.writeText(this.text);
			}
		}

	}
// </script>"