<template>
    <mw-dialog
        v-model="dialog"
        small
        cancel-btn
        submit-btn
        title="Are You Sure"
        @submit="submit"
    >
        <template v-slot:dialog-body>
            <p>{{  text }}</p>
        </template>
    </mw-dialog>


</template>

<script>
    import MwDialog from "@c/ui/MwDialog"
    export default {
        name: "AreYouSure", 
        data: () => {
            return {
                dialog: false,
            }
        },
        props: {
            value: { },
            text: {}
        },
        watch: {
            value(v){
                if( v  ){
                    this.dialog = true;
                }
            },
            dialog(v){
                if( !v ){
                    this.$emit('cancel')
                } 
            },
        },
        methods: {
            submit(){
                this.$emit('input')
            }
        },
        components: {
            MwDialog
        }
    }
</script>