<template>
    <v-list dense v-if="room">
        <v-list-item>
            <v-list-item-content>
                <v-list-item-title>
                    Waiting Room
                </v-list-item-title>
            </v-list-item-content>
        </v-list-item>
        <v-list-item v-for="user in waitingRoom" :key="user">
            <v-list-item-content >
                <v-list-item-subtitle>
                    {{ room.identities[user] }}
                </v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action>
                <v-btn icon @click="admitting = user">
                    <v-icon>mdi-check-circle</v-icon>
                </v-btn>
            </v-list-item-action>
        </v-list-item>
		<are-you-sure
			:value="admitting"
			@input="admit"
            @cancel="admitting = null"
            cancel-btn
			text="The person you are admitting does not have a verified account. Are you sure?"
		></are-you-sure>
		<v-list-item v-if="!haveWaiters">
			<v-list-item-subtitle>
				There's noone in the waiting room
			</v-list-item-subtitle>
		</v-list-item>
    </v-list>
</template>
<script>
import AreYouSure from "@c/notices/AreYouSure"

    export default {
        name: "WaitingRoom", 
        components: {
            AreYouSure
        },
        data() {
            return {
                admitting: false
            }
        },
        computed: {
            room(){
                return this.$store.state.videoRoom.data;  
            },
            waitingRoom(){
                const room = this.room;
                let waiting_room = room.waiting_room || [];
                return waiting_room.filter( r => room.identities[r] )
            }, 
            haveWaiters(){
                return this.waitingRoom.length > 0;
            }
        },
        methods: {
            admit(){
                this.$store.dispatch("videoRoom/admit",  this.admitting  )
            }
        }
    }

</script>
