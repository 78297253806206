import { render, staticRenderFns } from "./SessionControls.vue?vue&type=template&id=52e18931&scoped=true&"
import script from "./SessionControls.vue?vue&type=script&lang=js&"
export * from "./SessionControls.vue?vue&type=script&lang=js&"
import style0 from "./SessionControls.vue?vue&type=style&index=0&id=52e18931&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "52e18931",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabItem } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
installComponents(component, {VIcon,VList,VListItem,VListItemContent,VListItemIcon,VListItemTitle,VTab,VTabItem,VTabs})
