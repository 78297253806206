<template>
		<secondary-navigation show-back>
			<template v-slot:main>
				<nav-item v-if="!project.incentive_only"
					:link="`/projects/${id}/sessions`"
					icon="mdi-calendar"
					text="Sessions"
				></nav-item>
				<nav-item v-if="!project.incentive_only"
					:link="`/projects/${id}/participants`"
					icon="mdi-table-large"
					text="Participants"
				></nav-item>
				<nav-item v-if="!project.incentive_only"
					:link="`/recordings/${id}/`"
					icon="mdi-microphone"
					text="Recordings"
				></nav-item>
				<nav-item
					:link="`/documents/${id}/`"
					icon="mdi-file"
					text="Documents"
				></nav-item>
				<nav-item v-if="project.incentive_only"
					:link="`/incentives/${id}/add`"
					icon="mdi-currency-gbp"
					text="Incentives"
				></nav-item>
			</template>
		</secondary-navigation>
</template>

<script>
import NavItem from "@c/navigation/Item";
import SecondaryNavigation from "@c/navigation/Secondary.vue";
export default {
	name: "ProjectNavigation",
	props: {
		id: { type: String },
	},
	components: {
		SecondaryNavigation,
		NavItem,
	},
	computed: {
		project(){
			return this.$store.state.projects.data[this.id];
		},
	}
};
</script>
