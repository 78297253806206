<template>
    <mw-dialog small v-model="dialog" title="You are now the moderator">
        <template v-slot:dialog-body>
            Would you like to turn on your microphone and camera now? 

        </template>
        <template v-slot:dialog-buttons>
            <v-btn color="red" dark @click="dialog = false">No</v-btn>
            <v-btn color="primary" @click="turnOn">Yes</v-btn>
        </template>
    </mw-dialog>
</template>



<script>
import MwDialog from "@c/ui/MwDialog";
export default {
	name: "ModeratorAlert",
	components: {
		MwDialog,
	},
	data: () => {
		return {
			dialog: false,
		};
	},
	computed: {
		id() {
			return this.$store.state.videoRoom.data.id;
		},
		videoRoom(){
			return this.$store.state.videoRoom.data;
		},
		localIdentity() {
			return this.$store.state.auth.data.id;
		},
		isModerator(){
			return this.videoRoom.moderator == this.localIdentity
		}
	},
    watch: {
		isModerator: {
			immediate: true,
			handler(v){
				if( v ){
					this.dialog = true;
				}
			}
		}
    }, 
    methods: {
        turnOn(){
            this.$root.$emit("turnOnAv");
            this.dialog = false;
        }
    }
};
</script>
