<template>
	<v-card 
		flat
		min-height="160"
		color="lightest"
		class="participant mb-5" v-if="!isMain || haveVideo">
		<div v-if="haveVideo">
			<video-track
				size="preview"
				@select="select"
				v-for="track in videoTracks"
				:track="track"
				:key="track.sid"
			></video-track>
		</div>
		<participant-avatar
			v-else
			@select="select"
			v-model="identity"
		></participant-avatar>
		<audio-icon v-model="identity"></audio-icon>
		<participant-identity v-model="identity"></participant-identity>
		<v-chip color="primary" v-if="isModerator">Moderator</v-chip>
	</v-card>
</template>

<style lang="less"></style>

<script>
import ParticipantAvatar from "@c/video/ParticipantAvatar";
import ParticipantIdentity from "@c/video/ParticipantIdentity";
import VideoTrack from "@c/video/VideoTrack";
import AudioIcon from "@c/video/AudioIcon";
export default {
	name: "ParticipantThumbnail",
	props: {
		identity: { type: String },
	},
	data: () => {
		return {
		};
	},
	computed: {
		isMain(){
			return this.mainTrack.identity == this.identity;
		},
		videoRoom(){
			return this.$store.state.videoRoom.data
		},
		isModerator(){
			return this.videoRoom.isModerator == this.identity;
		},
		videoTracks(){
			let tracks = this.$store.getters["videoRoom/participantVideo"](this.identity);
			if( this.isMain && this.mainTrack ){
				let track = this.mainTrack.track;
				tracks = tracks.filter( a => a !== track ); 
			}
			return tracks;
		},
		haveVideo() {
			return this.videoTracks.length > 0;
		},
		mainTrack(){
			return this.$store.state.videoRoom.main_track || false;
		}
	},
	methods: {
		select(track) {
            this.$root.$emit("selectMainTrack", {
				track,
				identity: this.identity,
			});
		},
	},
	components: {
		ParticipantAvatar,
		ParticipantIdentity,
		AudioIcon,
		VideoTrack,
	},
};
</script>
